var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/domain-events.ts
import { filter } from "rxjs";
function filterEvents(type, $domainEvent) {
  return $domainEvent.pipe(
    filter((event) => event.type === type)
  );
}
__name(filterEvents, "filterEvents");
function addEventListener(eventSource, type, handler) {
  const parseAndHandle = /* @__PURE__ */ __name((event) => {
    const data = JSON.parse(event.data);
    handler({ ...event, data });
  }, "parseAndHandle");
  eventSource.addEventListener(type, parseAndHandle);
}
__name(addEventListener, "addEventListener");

// src/festival-activity.ts
var FESTIVAL_ACTIVITY_CREATED = "festival-activity-created";
var FESTIVAL_ACTIVITY_READY_TO_REVIEW = "festival-activity-ready-to-review";
var FESTIVAL_ACTIVITY_APPROVED = "festival-activity-approved";
var FESTIVAL_ACTIVITY_REJECTED = "festival-activity-rejected";
var FestivalActivity = class _FestivalActivity {
  static {
    __name(this, "FestivalActivity");
  }
  static created(festivalActivity, by) {
    const at = _FestivalActivity.computeAt();
    const data = { festivalActivity, by, at, id: festivalActivity.id };
    return { type: FESTIVAL_ACTIVITY_CREATED, data };
  }
  static readyToReview(festivalActivity, by) {
    const at = _FestivalActivity.computeAt();
    const data = { festivalActivity, by, at, id: festivalActivity.id };
    return { type: FESTIVAL_ACTIVITY_READY_TO_REVIEW, data };
  }
  static approved(festivalActivity, by) {
    const at = _FestivalActivity.computeAt();
    const data = { festivalActivity, by, at, id: festivalActivity.id };
    return { type: FESTIVAL_ACTIVITY_APPROVED, data };
  }
  static rejected(festivalActivity, by, reason) {
    const at = _FestivalActivity.computeAt();
    const data = { festivalActivity, by, at, id: festivalActivity.id, reason };
    return { type: FESTIVAL_ACTIVITY_REJECTED, data };
  }
  static computeAt() {
    const at = /* @__PURE__ */ new Date();
    at.setMilliseconds(0);
    return at;
  }
};

// src/festival-task.ts
var FESTIVAL_TASK_CREATED = "festival-task-created";
var FESTIVAL_TASK_READY_TO_REVIEW = "festival-task-ready-to-review";
var FESTIVAL_TASK_REJECTED = "festival-task-rejected";
var FESTIVAL_TASK_APPROVED = "festival-task-approved";
var FESTIVAL_TASK_IGNORED = "festival-task-ignored";
var FestivalTask = class _FestivalTask {
  static {
    __name(this, "FestivalTask");
  }
  static created(festivalTask, by) {
    const at = _FestivalTask.computeAt();
    const data = { festivalTask, by, at, id: festivalTask.id };
    return { type: FESTIVAL_TASK_CREATED, data };
  }
  static readyToReview(festivalTask, by) {
    const at = _FestivalTask.computeAt();
    const data = { festivalTask, by, at, id: festivalTask.id };
    return { type: FESTIVAL_TASK_READY_TO_REVIEW, data };
  }
  static rejected(festivalTask, by, reason) {
    const at = _FestivalTask.computeAt();
    const data = { festivalTask, by, at, id: festivalTask.id, reason };
    return { type: FESTIVAL_TASK_REJECTED, data };
  }
  static approved(festivalTask, by) {
    const at = _FestivalTask.computeAt();
    const data = { festivalTask, by, at, id: festivalTask.id };
    return { type: FESTIVAL_TASK_APPROVED, data };
  }
  static ignored(festivalTask, by) {
    const at = _FestivalTask.computeAt();
    const data = { festivalTask, by, at, id: festivalTask.id };
    return { type: FESTIVAL_TASK_IGNORED, data };
  }
  static computeAt() {
    const at = /* @__PURE__ */ new Date();
    at.setMilliseconds(0);
    return at;
  }
};

// src/shared-meal.ts
var SHARED_MEAL_CLOSED = "shared-meal-closed";

// src/registration.ts
var STAFF_REGISTERED = "staff-registered";
var VOLUNTEER_REGISTERED = "volunteer-registered";
export {
  FESTIVAL_ACTIVITY_APPROVED,
  FESTIVAL_ACTIVITY_CREATED,
  FESTIVAL_ACTIVITY_READY_TO_REVIEW,
  FESTIVAL_ACTIVITY_REJECTED,
  FESTIVAL_TASK_APPROVED,
  FESTIVAL_TASK_CREATED,
  FESTIVAL_TASK_IGNORED,
  FESTIVAL_TASK_READY_TO_REVIEW,
  FESTIVAL_TASK_REJECTED,
  FestivalActivity,
  FestivalTask,
  SHARED_MEAL_CLOSED,
  STAFF_REGISTERED,
  VOLUNTEER_REGISTERED,
  addEventListener,
  filterEvents
};
